<template>
  <div>
    <div class="profile-tabs">
      <p v-for="(tab, index) in tabList" :key="index || tabList.length" @click="clicked(tab.id)"
        :class="['tab-btn', { active: componentName === tab.id }]">
        {{ $t(tab.name) }}
      </p>
    </div>
    <div class="component-contains">
      <component :is="componentName" :locale="locale" :isCardUpdate="isCardUpdate"
        :cardUpdateDetailState="cardUpdateDetailState" :cardUpdateStatus="cardUpdateStatus" @gatewayList="setGatewayList">
      </component>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Utility from "@/mixins/Utility.js";

export default {
  props: {
    locale: {
      type: String,
    },

    paymentDetail: {
      type: Object,
    },

    isCardUpdate: {
      type: Boolean,
    },

    cardUpdateDetailState: {
      type: Object,
    },

    cardUpdateStatus: {
      type: Object,
    },
  },
  data() {
    return {
      componentName: "",
      localDisplayLang: null,
      gatewayListArr: [],
      subscriberDetails: null,
    };
  },

  watch: {
    subscriptionList(val) {
      if (val && val.length) {
        val.forEach((ele) => {
          if (
            ele.gwprovider == "CHECKOUT" &&
            this.profileid === this.subscriberDetails.profileid &&
            !JSON.stringify(this.tabList).includes(JSON.stringify({ name: "Update Card", id: "CardUpdate" }))
          ) {
            this.tabList.push({ name: "Update Card", id: "CardUpdate" });
            return;
          }
        });
      }
    },
  },

  computed: {
    ...mapGetters(["appConfig", "subscriptionList", "getIsManageCards", "profileid", "subscriberid"]),
  },

  created() {
    this.getUserInfo();

    this.setupTabList();
  },

  mounted() {

    if (this.appConfig.featureEnabled.isPlanEnable) {
      this.tabList.unshit({ name: "Plans", id: "plans" });
    }
    if (this.appConfig.featureEnabled.isTicketEnable) {
      this.tabList.unshit({ name: "Tickets", id: "tickets" },);
    }

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    let itemList = this.$route.params.listItem;
    this.componentName = itemList || this.tabList[0].id;

    if (this.isCardUpdate && this.cardUpdateDetailState) {
      this.componentName = "CardUpdate";
    }
  },

  methods: {
    ...mapMutations(["setSubscriptionList"]),

    clicked(data) {
      this.componentName = data;
      let currentLanguage = this.getCurrentLanguageUrlBase();

      this.$router.push({ name: "Profile", params: { lang: currentLanguage, listItem: data } })

    },

    getUserInfo() {
      this.isSubProfiles = false;
      if (localStorage.getItem("subscriberDetails")) {
        this.subscriberDetails = JSON.parse(localStorage.getItem("subscriberDetails")).data;
      }

      this.getCurrentProfileDetails();
    },

    getCurrentProfileDetails() {
      this.subscriberProfileDetails = localStorage.getItem("subscriberProfileDetails")
        ? JSON.parse(localStorage.getItem("subscriberProfileDetails"))
        : null;
    },

    setupTabList() {
      // Condition for admin Profile
      let kidsMode = (localStorage.getItem("isKidsMode") === 'true');
      if (this.profileid === this.subscriberid) {
        this.tabList = [
          // { name: "Tickets", id: "tickets" },
          { name: "Plans", id: "plans" },
          { name: "Parental Control", id: "parentalcontrol" },
          { name: "My Devices", id: "devices" },
          { name: "Feedback", id: "feedback" },
          // { name: "Purchases", id:"purchases"}

        ];
      } else {
        if (kidsMode) {
          // this.tabList = [{ name: "Tickets", id: "tickets" }];
        } else {
          this.tabList = [
            // { name: "Tickets", id: "tickets" },
            { name: "Parental Control", id: "parentalcontrol" },
            { name: "Feedback", id: "feedback" },
          ];
        }
      }

      this.addManageCardsToList();

      this.addDevicesListToMenu();
    },

    setGatewayList(val) {
      this.gatewayListArr = val;
    },

    addManageCardsToList() {

      if (this.profileid === this.subscriberDetails.profileid) {
        if (this.subscriptionList && this.subscriptionList.length) {
          this.subscriptionList.forEach((ele) => {
            if (ele.gwprovider == "CHECKOUT") {
              this.tabList.push({ name: "Update Card", id: "CardUpdate" });
              return;
            }
          });
        }
      }
    },

    addDevicesListToMenu() {
      if (this.appConfig.featureEnabled.isDevicesListEnabled && this.profileid === this.subscriberDetails.profileid) {
        this.tabList.push({ name: "Devices", id: "Devices" });
      }
    },
  },
  components: {
    plans: () => import(/* webpackChunkName: "tabs-plan" */ "./Tabs/Plans.vue"),
    devices: () => import(/* webpackChunkName: "tabs-device" */ "./Tabs/Devices.vue"),
    Bills: () => import(/* webpackChunkName: "tabs" */ "./Tabs/Bills.vue"),
    purchases: () => import(/* webpackChunkName: "tabs" */ "./Tabs/Purchases.vue"),
    tickets: () => import(/* webpackChunkName: "tabs-tickets" */ "./Tabs/Tickets.vue"),
    ActivityLog: () => import(/* webpackChunkName: "tabs" */ "./Tabs/Activities.vue"),
    Settings: () => import(/* webpackChunkName: "tabs" */ "./Tabs/Settings.vue"),
    CardUpdate: () => import(/* webpackChunkName: "tabs" */ "./CardUpdate.vue"),
    parentalcontrol: () => import("./Tabs/ParentalControl.vue"),
    feedback: () => import(/* webpackChunkName: "StarRating" */ "./Tabs/StarRating.vue"),

  },
  mixins: [Utility],

};
</script>


<style lang="scss">
@import "@/sass/_variables.scss";

.profile-tabs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-family: $font-regular;
  background-color: $clr-dark-gd4;
  padding: 1.5rem;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  .tab-btn {
    width: fit-content;
    margin: 5px 10px;
    text-align: center;
    padding: 7px;
    border-radius: 5px;
    background: transparent;
    //font-size: 0.95rem;
    font-weight: 600;
    color: $clr-parental-txt;
    cursor: pointer;
  }

  .active {
    // background: linear-gradient(180deg,#003AAC,#0073FA,#003AAC);
    background:linear-gradient(to right, #9000B9, #EA00FF,#9000B9);
    color: $clr-plnns-txt;
  }
}

.component-contains {
  padding: 20px 0 50px 0;
  margin-top: 0.3rem;
  font-family: $font-regular;
  background-color: $clr-dark-gd5;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

@media only screen and (max-width: 576px) {
  .profile-tabs {
    // display: grid;
    // grid-template-columns: repeat(3, 1fr);
    // padding: 1rem;
    justify-content: center;
  }

  .component-contains {
    padding: 0px;
  }
}
</style>
